import Head from "next/head";
import React from "react";

function HeadTags(props) {
    if (props.imageUrl && !props.imageUrl.startsWith("https://")) {
        throw new Error("og:image URL is not a full absolute URL required by Open Graph.");
    }

    const _title = `${props.title} | Ray Shan`;
    return (
        <Head>
            <title>{_title}</title>
            <link rel="canonical" href={props.url} />
            <meta name="description" content={props.description} />

            <meta property="og:title" content={_title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={props.description} />
            <meta property="og:url" content={props.url} />
            <meta property="twitter:title" content={_title} />

            {/* Specify width / height so FB can render the image immediately
                without having to asynchronously download and process it */}
            {/* https://developers.facebook.com/docs/sharing/best-practices/ */}
            {props.imageUrl && [
                <meta key="og:image" property="og:image" content={props.imageUrl} />,
                <meta key="og:image:width" property="og:image:width" content="1200" />,
                <meta key="og:image:height" property="og:image:height" content="630" />,
                <meta key="twitter:image" property="twitter:image" content={props.imageUrl} />,
            ]}

            {/* TODO: use react-schemaorg per Google's recommendation */}
            {/* https://github.com/google/react-schemaorg */}

            {/* Use dangerouslySetInnerHTML for next/head: https://github.com/vercel/next.js/issues/2213 */}
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: `{
                    "description": "${props.description}",
                    "@type": "WebPage",
                    "url": "${props.url}",
                    "headline": "${_title}",
                    "publisher": {
                        "@type": "Organization",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://finance.shan.io/favicon.png"
                        },
                        "name": "rayshan"
                    },
                    "author": {
                        "@type": "Person",
                        "name": "rayshan"
                    },
                    ${props.imageUrl ? `"image": "${props.imageUrl}"` : ""}
                    "@context": "https://schema.org"
                }`}}
            />
        </Head>
    );
}

export default HeadTags;
