import React from 'react';
import clsx from "clsx";

import HeadTags from "HeadTags";

import chromeLogoSrc from "./appStoreLogos/chrome.svg";
import edgeLogoSrc from "./appStoreLogos/edge.svg";
import microsoftLogoSrc from "./appStoreLogos/microsoft.svg";
import firefoxLogoSrc from "./appStoreLogos/firefox.png";
// import {ReactComponent as AppleLogo} from "./appStoreLogos/apple.svg";
// import {ReactComponent as SafariLogo} from "./appStoreLogos/safari.svg";
// import operaLogoSrc from "./appStoreLogos/opera.png";

import screenshot1Src from "./screenshots/stock-inspector-browser-extension-screenshot-1.png";
import screenshot2Src from "./screenshots/stock-inspector-browser-extension-screenshot-2.png";
import screenshot3Src from "./screenshots/stock-inspector-browser-extension-screenshot-3.png";
import screenshot4Src from "./screenshots/stock-inspector-browser-extension-screenshot-4.png";
import screenshot5Src from "./screenshots/stock-inspector-browser-extension-screenshot-5.png";
import screenshot6Src from "./screenshots/stock-inspector-browser-extension-screenshot-6.png";

import ogImg from "./stock-inspector-discover-new-companies-etfs.jpg";
import styles from "./StockInspectorOverview.module.scss";

// =================================================================================================

class StockInspectorOverview extends React.Component {
    static headTagsProps = {
        title: "Stock Inspector - discover new companies & ETFs",
        url: "https://finance.shan.io/stock-inspector-discover-new-companies-etfs/",
        description: "Discover stocks & ETFs while you browse. Watch latest ticker prices, understand company finances, zoom through portfolio analysis.",
        imageUrl: `https://${process.env.NEXT_PUBLIC_PUBLIC_URL}${ogImg}`,
    };

    get downloadLinks() {
        return (
            <div className={styles["download-links"]}>
                <a
                    className={clsx(styles["download-link"], styles.chrome)}
                    href="https://chrome.google.com/webstore/detail/stock-inspector-discover/bjbgncfjoplpeldfafkkdpmdpkhkmadb"
                    target="_blank"
                >
                    <img className={styles["badge"]} src={chromeLogoSrc} alt="Download Stock Inspector for Chrome" />
                </a>
                <a
                    className={clsx(styles["download-link"], styles.firefox)}
                    href="https://addons.mozilla.org/en-US/firefox/addon/stock-inspector-discover-etfs/"
                    target="_blank"
                >
                    <img className={styles["badge"]} src={firefoxLogoSrc} alt="Download Stock Inspector for Firefox" />
                </a>
                {/*<a*/}
                {/*    className={clsx(styles["download-link"], styles.opera)}*/}
                {/*    href="https://addons.opera.com/en/extensions/details/stock-inspector-discover-new-stocks-etfs/"*/}
                {/*    target="_blank"*/}
                {/*>*/}
                {/*    <img className={styles["badge"]} src={operaLogoSrc} alt="Download Stock Inspector for Opera" />*/}
                {/*</a>*/}
                <a
                    className={clsx(styles["download-link"], styles.edge)}
                    href="https://microsoftedge.microsoft.com/addons/detail/stock-inspector-discove/hohfheedgehmdaofmbjaofdkgkiipkob"
                    target="_blank"
                >
                    <img className={styles["badge"]} src={microsoftLogoSrc} alt="Download Stock Inspector for Chrome" />
                    <img className={styles["icon"]} src={edgeLogoSrc} alt="Download Stock Inspector for Chrome" />
                    {/*<MicrosoftLogo className={styles["badge"]} /><EdgeLogo className={styles["icon"]} />*/}
                </a>
                {/*<div className={clsx(styles["download-link"], styles.safari)}>*/}
                {/*    /!*<AppleLogo className={styles["badge"]} /><SafariLogo className={styles["icon"]} />*!/*/}
                {/*</div>*/}
            </div>
        );
    }

    get description() {
        return (
            <article className={clsx(styles["article"], styles["description"])}>
                <p>
                    Do you shop at a store so much and wonder if you can buy its stock? Do you want to keep an eye on your favorite stocks with just a click away?
                </p>

                <p>
                    Stock Inspector shows you stocks and EFTs related to the website you visit. You can quickly see how a stock is growing, get info and tips on the company, and more:
                </p>

                <h2>🔍 Discover hidden stocks</h2>
                <figure className={styles["screenshot"]}>
                    <img src={screenshot1Src} alt="Stock Inspector - Discover hidden stocks, mutual funds and ETFs as you browse the web" />
                </figure>
                <ul>
                    <li>
                        Stock Inspector knows about popular products owned by public companies, like YouTube and Alphabet (GOOG)
                    </li>
                    <li>
                        Subsidiaries show up too, like Blue Bottle Coffee and Nestlé (NSRGY)
                    </li>
                    <li>
                        Search the app to find an exact ticker
                    </li>
                </ul>

                <h2>👩‍🎓 Use it where you work</h2>
                <figure className={styles["screenshot"]}>
                    <img src={screenshot2Src} alt="Stock Inspector - Keep an eye on your favorite company’s stock and ETF" />
                </figure>
                <ul>
                    <li>
                        Browse the web as you normally do. If you see the latest price change on the
                        icon, it means that there’s a stock related to the website you’re on.
                    </li>
                    <li>
                        Add what you find to your watchlist and keep an eye on your portfolio
                    </li>
                    <li>
                        Trade commission-free with your favorite brokers like Robinhood
                    </li>
                </ul>

                <h2>📚 Learn more with 1-click</h2>
                <figure className={styles["screenshot"]}>
                    <img src={screenshot4Src} alt="Stock Inspector - Learn more and trade with 1-click to Yahoo Finance, Robinhood, Morningstar and other brokers / reference sources" />
                </figure>
                <ul>
                    <li>
                        Click to see the latest price and company stats
                    </li>
                    <li>
                        Learn more about public companies from Yahoo Finance, Google Finance, Twitter tweets and Morningstar
                    </li>
                    <li>
                        Learn more about private companies from Crunchbase, Pitchbook and LinkedIn
                    </li>
                </ul>
                <figure className={styles["screenshot"]}>
                    <img src={screenshot3Src} alt="Stock Inspector - Answer tough questions about the company and its stock" />
                </figure>

                <h2>💡 Tips & tricks</h2>
                <figure className={styles["screenshot"]}>
                    <img src={screenshot5Src} alt="Stock Inspector - Pin to your browser toolbar to discover all the hidden stocks" />
                </figure>
                <ul>
                    <li>
                        Pin the extension to your browser toolbar so it can show you what it found.
                    </li>
                    <li>
                        Ctrl / Cmd + Shift + S pops open the Stock Inspector. You can customize this
                        keyboard shortcut too!
                    </li>
                </ul>

                <figure className={styles["screenshot"]}>
                    <img src={screenshot6Src} alt="Stock Inspector - Users love it, 5-star reviews" />
                </figure>
            </article>
        );
    }

    render() {
        return (
            <section className="content" id={styles["stock-inspector-overview"]}>
                <HeadTags {...StockInspectorOverview.headTagsProps} />
                <h1>
                    Stock Inspector
                    <br />
                    <span className="subtitle">Discover new companies & ETFs</span>
                </h1>
                {this.downloadLinks}
                {this.description}
            </section>
        );
    }
}

export default StockInspectorOverview;
